import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/logos/SKBZ-logo.png";

function Navbar() {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title" onClick={handleClick}>
        <img
          src={logo}
          style={{ width: "70px", height: "70px", marginRight: "5px" }}
          alt="News"
        />
        <span className="navbar-title-span">SKBZ-MZD+</span>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <a href="/" className="navbar-links">
            Home
          </a>
        </li>
        <li>
          <a href="/departments" className="navbar-links">
            Departments
          </a>
        </li>
        <li>
          <a href="/about" className="navbar-links">
            About
          </a>
        </li>
        <li>
          <a href="/doctors" className="navbar-links">
            Doctors
          </a>
        </li>
        <li>
          <a href="/news" className="navbar-links">
            News
          </a>
        </li>
        <li>
          <a href="/gallery" className="navbar-links">
            Gallery
          </a>
        </li>
      </ul>

      <button
        className="navbar-btn"
        type="button"
        onClick={() => {
          navigate("/contact"); // Navigate to the Contact page
        }}
      >
        Contact
      </button>

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <Link onClick={openNav} to="/">
              Home
            </Link>
          </li>
          <li>
            <a onClick={openNav} href="/departments">
              Departments
            </a>
          </li>
          <li>
            <a onClick={openNav} href="/about">
              About
            </a>
          </li>
          <li>
            <a onClick={openNav} href="/doctors">
              Doctors
            </a>
          </li>
          <li>
            <a onClick={openNav} href="/news">
              News
            </a>
          </li>
          <li>
            <a onClick={openNav} href="/gallery">
              Gallery
            </a>
          </li>
        </ul>
      </div>

      {/* Hamburger Icon */}
      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );
}

export default Navbar;
