// src/Pages/Contact.js
import React from "react";
import AboutHero from "../Components/AboutComp/AboutHero";
import "../Styles/Info.css";

const aboutArray = [
  {
    id: 1,
    heading: "Foundation and Funding",
    description: [
      {
        subHeading: "",
        para: "The Sheikh Khalifa Bin Zayed Al Nahyan Hospital (SKBZ-MZD) in Muzaffarabad, Azad Jammu and Kashmir (AJK), Pakistan, stands as a testament to international cooperation and philanthropy. This hospital, named after the late President of the United Arab Emirates (UAE), Sheikh Khalifa Bin Zayed Al Nahyan, is an emblem of the deep ties between Pakistan and the UAE. The foundation and funding of SKBZ-MZD Hospital reveal a compelling story of humanitarian assistance, strategic partnership, and the pursuit of improved healthcare infrastructure in a region significantly affected by natural disasters and socio-economic challenges.",
      },
      {
        subHeading: "Historical Context",
        para: "Muzaffarabad, the capital of AJK, experienced significant devastation during the 2005 earthquake, which necessitated urgent attention to its healthcare infrastructure. The earthquake, one of the deadliest in the region, resulted in the loss of thousands of lives and left many more injured and displaced. The catastrophe underscored the need for robust medical facilities that could handle such emergencies and provide ongoing healthcare services. It was against this backdrop that the idea of establishing a modern, well-equipped hospital took root.",
      },
      {
        subHeading: "UAE’s Commitment to Humanitarian Aid",
        para: "The UAE has a long-standing tradition of providing humanitarian aid and supporting development projects worldwide. This commitment is rooted in the vision of the UAE's founding father, Sheikh Zayed bin Sultan Al Nahyan, who emphasized the importance of aiding those in need, regardless of geographic or political boundaries. His legacy continues through various initiatives led by the UAE government and charitable organizations.The decision to fund a major hospital in Muzaffarabad was in line with the UAE's broader strategy of international assistance and was aimed at fostering closer bilateral relations with Pakistan. The project was conceived not merely as a charitable act but as a strategic investment in the health and well-being of the people of AJK.",
      },
      {
        subHeading: "Project Inception",
        para: "The inception of the SKBZ-MZD Hospital project began with discussions between the governments of Pakistan and the UAE. Recognizing the critical need for advanced medical facilities in the earthquake-stricken region, the UAE pledged substantial financial support. The agreement highlighted the UAE's commitment to providing quality healthcare infrastructure to its Pakistani counterparts, reflecting a deep-seated spirit of solidarity and friendship.",
      },
      {
        subHeading: "Financial Contributions and Infrastructure Development",
        para: "The UAE's financial contribution to the SKBZ-MZD Hospital project was substantial. The total funding covered not only the construction of the hospital building but also the procurement of state-of-the-art medical equipment, training for medical staff, and the establishment of ancillary facilities such as laboratories and pharmacies. The funding was aimed at ensuring that the hospital could offer a comprehensive range of medical services, from emergency care to specialized treatments. The hospital's construction involved collaboration with leading architectural and construction firms to ensure that the facility met international standards. The design of the hospital incorporated features to withstand seismic activity, reflecting the lessons learned from the 2005 earthquake. Additionally, the hospital was equipped with advanced medical technologies, including modern diagnostic and treatment equipment, to provide high-quality healthcare services.",
      },
      {
        subHeading: "Implementation and Oversight",
        para: "The implementation of the SKBZ-MZD Hospital project was overseen by a dedicated team comprising representatives from both the UAE and Pakistan. This team was responsible for ensuring that the project adhered to the agreed timelines and quality standards. Regular site visits, progress reports, and audits were conducted to monitor the project's advancement and address any challenges that arose. One of the critical aspects of the project was the training of medical personnel. The funding included provisions for sending Pakistani doctors, nurses, and technicians to the UAE for specialized training. This initiative was designed to ensure that the staff could effectively utilize the advanced medical equipment and adhere to international best practices in patient care.",
      },
      {
        subHeading: "Impact on Local Healthcare",
        para: "Since its inauguration, SKBZ-MZD Hospital has had a transformative impact on the local healthcare landscape in Muzaffarabad. The hospital provides a wide range of medical services, including emergency care, surgery, internal medicine, pediatrics, gynecology, and more. Its advanced facilities have made it possible to offer treatments that were previously unavailable in the region, thereby reducing the need for patients to travel to other cities for specialized care. The hospital has also played a critical role during health emergencies, including outbreaks of diseases and natural disasters. Its well-equipped emergency department and trained medical staff have been instrumental in providing timely and effective responses to such crises. Furthermore, the hospital has become a center for medical education and training, fostering the development of local healthcare professionals.",
      },
      {
        subHeading: "Symbol of UAE-Pakistan Friendship",
        para: "The Sheikh Khalifa Bin Zayed Al Nahyan Hospital stands as a symbol of the enduring friendship between the UAE and Pakistan. It reflects the UAE's commitment to supporting Pakistan's development and the shared values of compassion and humanitarianism that underpin the relationship between the two countries. The hospital's establishment is not only a response to an immediate need but also an investment in the long-term health and well-being of the people of AJK. The hospital's foundation and funding underscore the importance of international cooperation in addressing global health challenges. It highlights how strategic partnerships and financial investments can lead to substantial improvements in healthcare infrastructure, ultimately benefiting entire communities.",
      },
    ],
  },
  {
    id: 2,
    heading: "Facilities and Services",
    description: [
      {
        subHeading: "",
        para: "The Sheikh Khalifa Bin Zayed Al Nahyan Hospital (SKBZ-MZD) in Muzaffarabad, Azad Jammu and Kashmir (AJK), Pakistan, is a premier healthcare institution that epitomizes advanced medical care, state-of-the-art facilities, and comprehensive services. Established with significant financial and logistical support from the United Arab Emirates (UAE), the hospital is designed to address the healthcare needs of the local population, providing a wide array of medical services under one roof. This essay delves into the various facilities and services offered by SKBZ-MZD Hospital, highlighting its critical role in enhancing the healthcare landscape of the region.",
      },
      {
        subHeading: "State-of-the-Art Infrastructure",
        para: "The infrastructure of SKBZ-MZD Hospital is a testament to modern architectural design and advanced engineering. Built to withstand seismic activities, the hospital's structure incorporates earthquake-resistant technologies, ensuring the safety of patients and staff in the event of natural disasters. The design of the hospital emphasizes both functionality and patient comfort, featuring spacious wards, well-ventilated rooms, and aesthetically pleasing interiors that promote a healing environment.",
      },
      {
        subHeading: "Comprehensive Medical Services",
        para: "SKBZ-MZD Hospital offers a broad spectrum of medical services, ensuring that the healthcare needs of the community are met comprehensively. The services are organized into various specialized departments, each staffed by highly trained medical professionals.",
      },
      {
        subHeading: "Emergency Care",
        para: "The emergency department is equipped to handle a wide range of medical emergencies, from trauma cases to acute medical conditions. It operates 24/7, ensuring that patients receive immediate and effective care. The department includes a well-equipped trauma center, emergency surgical units, and intensive care facilities.",
      },
      {
        subHeading: "Rehabilitation and Physiotherapy",
        para: "The hospital recognizes the importance of rehabilitation in the recovery process. The rehabilitation and physiotherapy department offers services to patients recovering from surgery, injury, or chronic conditions. The department is equipped with modern physiotherapy equipment and staffed by trained physiotherapists who provide personalized rehabilitation programs.",
      },
      {
        subHeading: "Pharmacy Services",
        para: "SKBZ-MZD Hospital includes a fully stocked pharmacy that ensures the availability of essential medications. The pharmacy services are integrated with the hospital’s electronic health records, allowing for efficient prescription management and minimizing medication errors. The pharmacy also provides counseling on medication use and management of chronic conditions.",
      },
      {
        subHeading: "Training and Education",
        para: "SKBZ-MZD Hospital also serves as a center for medical education and training. The hospital provides continuing education opportunities for its staff, ensuring that they stay updated with the latest medical advancements and practices. Training programs include workshops, seminars, and partnerships with medical institutions for advanced training.",
      },
    ],
  },
];

const About = () => {
  return (
    <div>
      <AboutHero />
      <div className="info-section" id="services">
        {aboutArray?.map((item) => {
          return (
            <div className="info-title-content" key={item.id}>
              <h3 className="info-title">
                <span>{item.heading}</span>
              </h3>
              {item?.description?.map((innerItem, innerIndex) => {
                return (
                  <div key={innerIndex}>
                    <h2 className="info-subheading">{innerItem.subHeading}</h2>
                    <p className="info-aboutDescription">{innerItem.para}</p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default About;
