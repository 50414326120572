import React from "react";

const credentialsArray = [
  {
    heading: "Appointments",
    appointmentArr: [
      "    Neurologist-in-Chief, Hospital for Special Surgery",
      "Chair, Marcia Dunn and Jonathan Sobel Department of Neurology, Hospital for Special Surgery.",
      "Attending Neurologist, Hospital for Special Surgery",
    ],
  },
  {
    heading: "Affiliations",
    appointmentArr: [
      "American Academy of Neurology, Member",
      "American Academy of Neuromuscular and Electrodiagnostic Medicine, Member",
    ],
  },
  {
    heading: "Certification",
    appointmentArr: [
      "Clinical Neuromuscular Pathology/United Council of Neurologic Subspecialties, 2023",
      "​​​​​​​Neuromuscular Medicine/American Board of Neurology and Psychology, 2008",
      "Neuromuscular Pathology/American Academy of Neurology, 2005",
      "Neurology/American Board of Neurology and Psychology, 2004",
    ],
  },
  {
    heading: "Awards",
    appointmentArr: [
      "Castle Connolly Top Doctor, 2024",
      "Boston Magazine’s Top Doctor, 2023",
      "Outstanding Teaching Award, Icahn School of Medicine at Mount Sinai Department of Neurology, 2013",
      "Travel Award, American Society of Clinical Investigation/Association of American Physicians, 2007",
      "Resident Scholarship, American Academy of Neurology, 2002",
      "Chief Resident, Wayne State University Department of Neurology, 2001",
      "Honorable Mentioning, Fifteenth Annual Graduate Student Research Forum, University of Cincinnati College of Medicine, 1995",
      "Albert J. Ryan Fellowship, The Albert J. Ryan Foundation (awarded to outstanding graduate students at the University of Cincinnati, Harvard Medical School, and Dartmouth College), 1994",
    ],
  },
  {
    heading: "Education",
    appointmentArr: [
      "Medical: Shanghai Medical University School of Medicine (now Fudan University Medical Center), Shanghai, China, 1989",
      "Doctorate: Developmental Biology, University of Cincinnati College of Medicine, Cincinnati, Ohio, 1995",
      "Internship: Medicine Internship, Department of Internal Medicine, Wayne State University Sinai-Grace Hospital, Detroit, MI, 1999",
      "Residency: Neurology Residency, Wayne State University School of Medicine Detroit, MI, 2002",
      "Fellowship: Neuromuscular Fellowship, Johns Hopkins University Hospital, Baltimore, MD, 2003",
    ],
  },
  {
    heading: "State Licensure",
    appointmentArr: ["New York, Massachusetts"],
  },
  {
    heading: "Languages",
    appointmentArr: ["English, Chinese (Mandarin and Shanghainese)"],
  },
];

function Credentials() {
  return (
    <div
      id="credentials"
      style={{
        width: "100%",
      }}
    >
      <h1 style={{ fontWeight: "bold", marginTop: 30 }}>Credentials</h1>
      <hr
        style={{
          border: "1px solid black",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
      />
      {credentialsArray.map((item, index) => {
        return (
          <div>
            <h2
              key={index}
              style={{ fontWeight: "bold", marginTop: 30, marginBottom: 30 }}
            >
              {item.heading}
            </h2>
            {item.appointmentArr.map((innerItem, innerIndex) => {
              return (
                <p
                  key={innerIndex}
                  style={{
                    fontSize: "17px",
                    marginTop: 5,
                    lineHeight: "25px",
                    textAlign: "justify",
                  }}
                >
                  {innerItem}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Credentials;
