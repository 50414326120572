import React, { useState } from "react";
import "../Styles/contact.css";
import ContactBanner from "../Assets/contact-us.avif";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [errors, setErrors] = useState({
    number: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateNumber = (number) => {
    const numberPattern = /^(?:\+92|0)?3[0-9]{9}$/;
    return numberPattern.test(number);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    const numberValid = validateNumber(formData.number);
    const emailValid = validateEmail(formData.email);

    if (!numberValid || !emailValid) {
      setErrors({
        number: numberValid
          ? ""
          : "Please enter a valid Pakistan phone number.",
        email: emailValid ? "" : "Please enter a valid email address.",
      });
      setIsSubmitting(false);
      return;
    } else {
      setErrors({
        number: "",
        email: "",
      });
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      subject: "Contact Form Submission",
      body: `Message: ${formData.message}\n\nRegards,\n${formData.name}\nPhone Number: ${formData.number}\nEmail: ${formData.email}`
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://skbz-mzd.com/admin/api/v1/send-email",
        requestOptions
      );
      if (!response.ok) {
        const textResponse = await response.text();
        console.error("Response error:", textResponse);
        throw new Error(
          `Failed to send data: ${response.status} ${response.statusText}`
        );
      }

      const result = await response.json();
      setSuccessMessage("Your message has been sent successfully!");
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        "There was a problem sending your message. Please try again later."
      );
    } finally {
      setIsSubmitting(false);
      setFormData({
        name: "",
        number: "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <div className="contact_form">
      <div className="container">
        <div className="banner-section">
          <img src={ContactBanner} alt="Banner" className="banner-image" />
        </div>
        <div className="form-section">
          <form className="contact-form" onSubmit={handleSubmit}>
            <h2>Contact Us</h2>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="number">Phone Number:</label>
            <input
              type="tel"
              id="number"
              name="number"
              value={formData.number}
              onChange={handleChange}
              required
            />
            {errors.number && <p className="error-message">{errors.number}</p>}

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error-message">{errors.email}</p>}

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;
