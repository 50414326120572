import React from "react";
import "../Styles/Hero.css";
import ReactPlayer from "react-player";
import heroVideo from "../Assets/hero-video.mp4";

function HeroVideo() {
  return (
    <div className="hero-video-container">
      <ReactPlayer
        url={heroVideo}
        controls={false}
        playing={true}
        loop={true}
        width="100%"
        height="40%"
        muted={true}
      />
    </div>
  );
}

export default HeroVideo;
