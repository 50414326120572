import React, { useEffect, useState } from "react";
import "../Styles/NewsList.css";
import logo from "../Assets/News-Banner2.jpeg";
import { Link } from "react-router-dom";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewsPage = () => {
  const [listData, setListData] = useState();

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://skbz-mzd.com/admin/api/v1/all-news", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setListData(result?.data?.NewsData);
      })
      .catch((error) => console.error(error));
  };
  return (
    <div className="news-page">
      <div className="news-page-section">
        <div style={{ width: "100%", height: "50vh" }}>
          <img
            style={{ width: "100%", height: "100%" }}
            src={logo}
            alt="News"
          />
        </div>
        <h2
          style={{
            marginBottom: "30px",
            fontWeight: "normal",
            paddingTop: "20px",
          }}
        >
          NEWS & ANNOUNCEMENT
        </h2>
        <div className="news-section-list">
          {listData?.map((item, index) => {
            return (
              <div className="news-section-list-item" key={index}>
                <h4 style={{ marginBottom: "10px" }}>{item?.name}</h4>
                <p>
                  <FontAwesomeIcon
                    className="info-fa-icon"
                    icon={faCalendarDays}
                  />{" "}
                  {item?.created_at}
                </p>
                <div className="image-side-list-item">
                  <img src={item?.img} alt="News" />
                </div>
                <Link className="btn-side-list-item" to={`/news/${item.id}`}>
                  Read more
                </Link>
                <div
                  style={{
                    height: "1px",
                    backgroundColor: "gray",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                ></div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewsPage;
