import React, { useEffect, useState } from "react";
import DoctorCard from "./DoctorCard";
import "../Styles/Doctors.css";

function Doctors() {
  const [doctorsList, setDoctorsList] = useState([]);


  useEffect(() => {
    getDoctorsList();
  }, []);

  const getDoctorsList = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://skbz-mzd.com/admin/api/v1/all-doctors", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const doctors = result?.data?.doctors || [];
        // Filter the doctors
        const filtered = doctors.filter((doctor) =>
          [
            "Dr. Adnan Mehraj",
            "Dr. Muzaffar",
            "Dr. Babar Bilal",
            "Dr. Amir Iqbal Ali",
          ].includes(doctor.name)
        );

        setDoctorsList(filtered);
        console.log("Filtered Doctors: ", filtered);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctors</span>
        </h3>
        <p className="dt-description">
          Meet our exceptional team of specialist doctors, dedicated to
          providing top-notch healthcare services at SKBZ. Trust in their
          knowledge and experience to lead you towards a healthier and happier
          life.
        </p>
      </div>

      <div className="dt-cards-content">
        {doctorsList?.map((item, index) => {
          return (
            <DoctorCard
              key={index}
              img={item?.img}
              name={item?.name}
              title={item?.title}
              degree={item?.degree}
            />
          );
        })}
      </div>

      <a style={{ fontSize: "18x", textDecoration: "none" }} href="/doctors">
        View More Doctors
      </a>
    </div>
  );
}

export default Doctors;
