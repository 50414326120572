import React from "react";
import logo from "../../Assets/logos/logo-footer.png";

function FooterLogo() {
  return (
    <div
      style={{
        width: "200px",
        height: "90px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt="logo"
        width={"50px"}
        height={"50px"}
        style={{ borderRadius: "10px" }}
      />
      <p style={{ color: "#cbcdd3", paddingTop: "5px" }}>Celestial&Dimanche</p>
    </div>
  );
}

export default FooterLogo;
