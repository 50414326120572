import React from "react";
import Doctor from "../../Assets/about-1.jpeg";
import "../../Styles/Hero.css";

function AboutHero() {
  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">
            Sheikh khalifa bin zayed al nahyan hospital muzaffarabd
          </p>
          <p className="text-descritpion">
            The Sheikh Khalifa Bin Zayed Al Nahyan Hospital, commonly known as
            SKBZ-MZD Hospital, is a prominent healthcare facility located in
            Muzaffarabad, the capital of Azad Jammu and Kashmir (AJK), Pakistan.
          </p>
          <p className="text-descritpion">
            The Sheikh Khalifa Bin Zayed Al Nahyan Hospital in Muzaffarabad
            stands as a symbol of UAE-Pakistan friendship and cooperation,
            providing essential healthcare services and improving the quality of
            life for many people in the region.
          </p>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>145k+</p>
              <p>Receive Patients</p>
            </div>

            <div className="text-stats-container">
              <p>50+</p>
              <p>Expert Doctors</p>
            </div>

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
          </div>
        </div>
        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>
    </div>
  );
}

export default AboutHero;
