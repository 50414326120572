import React from "react";
import Info from "../Components/Info";
import About from "../Components/About";
import Doctors from "../Components/Doctors";
import HeroVideo from "../Components/Hero-Video";

function Home() {
  return (
    <div className="home-section">
      <HeroVideo />
      <Info />
      <About />
      <Doctors />
    </div>
  );
}

export default Home;
