import React, { useEffect, useState } from "react";
import "../Styles/Doctors.css";
import DoctorCard from "../Components/DoctorCard";

function MeetTheDoctors() {
  const [doctorsList, setDoctorsList] = useState();

  useEffect(() => {
    getDoctorsList();
  }, []);

  const getDoctorsList = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://skbz-mzd.com/admin/api/v1/all-doctors", requestOptions)
      .then((response) => response.json())
      .then((result) => setDoctorsList(result?.data?.doctors))
      .catch((error) => console.error(error));
  };

  console.log("doctorsList", doctorsList);

  return (
    <div className="doctor-section" id="doctors">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Doctors</span>
        </h3>

        <p className="dt-description">
          Meet our exceptional team of specialist doctors, dedicated to
          providing top-notch healthcare services at SKBZ. Trust in their
          knowledge and experience to lead you towards a healthier and happier
          life.
        </p>
      </div>

      <div className="dt-cards-content">
        {doctorsList?.map((item, index) => {
          return (
            <DoctorCard
              key={index}
              img={item?.img}
              name={item.name}
              title={item.title}
              degree={item.degree}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MeetTheDoctors;
