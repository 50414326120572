import React, { useState } from "react";
import Doctor from "../Assets/logos/SKBZ-logo.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";
import HorizontalScroll from "./HorizontalScroll";

const TruncatedText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedText =
    text.length > 300 ? text.slice(0, 300) + "...see more" : text;

  return (
    <div>
      {isExpanded ? (
        <p className="about-description">
          {text}{" "}
          <span
            onClick={toggleExpand}
            style={{ color: "blue", cursor: "pointer" }}
          >
            ...see less
          </span>
        </p>
      ) : (
        <p className="about-description">
          {truncatedText.slice(0, 300)}
          {text.length > 300 && (
            <span
              onClick={toggleExpand}
              style={{ color: "blue", cursor: "pointer" }}
            >
              ...see more
            </span>
          )}
        </p>
      )}
    </div>
  );
};
function About() {
  const images = [
    {
      id: 1,
      img: require("../Assets/Gallery/6.jpeg"),
      name: "Paeds Department",
    },
    { id: 1, img: require("../Assets/Gallery/7.jpeg"), name: "Paeds HDU" },
    { id: 1, img: require("../Assets/Gallery/9.jpeg"), name: "Emergency" },
    { id: 1, img: require("../Assets/Gallery/10.jpeg"), name: "Gynae OPD" },
    { id: 1, img: require("../Assets/Gallery/11.jpeg"), name: "Gynae OPD" },
    { id: 1, img: require("../Assets/Gallery/12.jpeg"), name: "NICU" },
    { id: 1, img: require("../Assets/Gallery/13.jpeg"), name: "NICU" },
    {
      id: 1,
      img: require("../Assets/Gallery/14.jpeg"),
      name: "Rehabilitation",
    },
    {
      id: 1,
      img: require("../Assets/Gallery/15.jpeg"),
      name: "Rehabilitation",
    },
    {
      id: 1,
      img: require("../Assets/Gallery/17.jpeg"),
      name: "Doctor's Hostel",
    },
    { id: 1, img: require("../Assets/Gallery/18.jpeg"), name: "Library" },
    { id: 1, img: require("../Assets/Gallery/19.jpeg"), name: "CCU" },
  ];

  const longText = `H.H. Sheikh Khalifa Bin Zayed Al Nahyan Hospital Muzaffarabad is a
  400-bedded hospital, which provides secondary and tertiary care
  facilities to civilian population of about 1.8 million in addition to
  armed forces personnel. It is strategically located at a prime
  location in Muzaffarabad, the capital of the state of Azad Jammu and
  Kashmir, Pakistan. The hospital comprises of modern operation
  theaters, intensive care unit, NICU, HDU, Labor Room, 24/7 emergency,
  24/7 Gynae OPD. Highly qualified and experienced team of Consultants,
  Nurses, Paramedical Staff and other professionals run various
  departments of the hospital and ensure provision of quality medical
  services to the patients. The hospital facilitates indoor and outdoor
  treatment to the patient in every medical field. H.H. Sheikh Khalifa
  Bin Zayed Al Nahyan Hospital Muzaffarabad affiliated with Azad Jammu
  and Kashmir Medical College, Muzaffarabad and also recognized by
  College of Physicians and Surgeons Pakistan in 13 disciplines for
  postgraduate training. The house job training is full time residential
  and internees are involved in emergency care, ward work and
  outpatients clinics.`;

  return (
    <div className="about-div">
      <div className="about-section" id="about">
        <div className="about-image-content">
          <img src={Doctor} alt="Doctor Group" className="about-image1" />
        </div>

        <div className="about-text-content">
          <h3 className="about-title">
            <span>About Us</span>
          </h3>
          <TruncatedText text={longText} />
          {/* <p className="about-description">
          
        </p> */}

          <h4 className="about-text-title">Your Solutions</h4>

          <SolutionStep
            title="Choose a Specialist"
            description="Find your perfect specialist and book with ease at Health Plus. Expert doctors prioritize your health, offering tailored care."
          />

          <SolutionStep
            title="Make a Schedule"
            description="Choose the date and time that suits you best, and let our dedicated team of medical professionals ensure your well-being with personalized care."
          />

          <SolutionStep
            title="Get Your Solutions"
            description="Our experienced doctors and specialists are here to provide expert advice and personalized treatment plans, helping you achieve your best possible health."
          />
        </div>
      </div>
      <h3 className="about-title">
        <span>Gallery</span>
      </h3>
      <div className="about-images">
        <HorizontalScroll images={images} />
      </div>
      <a style={{ fontSize: "18px", textDecoration: "none" }} href="/gallery">
        View Gallery
      </a>
    </div>
  );
}

export default About;
