import React from "react";
import Doctor from "../../Assets/News-Banner2.jpeg";

function Hero() {
  return (
    <div
      id="hero"
      style={{
        display: "flex",
        alignItems: "center",
        gap: "24px",
        background: "linear-gradient(to right, #ECF2FF, #FBFCFF)",
        padding: "1% 5%",
      }}
    >
      <div
        style={{
          width: "75%",
        }}
      >
        <h1 style={{ color: "black", textAlign: "center" }}>Lan Zhou, MD</h1>
        <h3
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "center",
          }}
        >
          Neurology
        </h3>
        <div style={{ display: "flex" }}>
          <h3>Call: </h3>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>
            +92-584-8848485
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <h3>Fax: </h3>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>917.260.3709</p>
        </div>
        <hr
          style={{
            border: "1px solid black",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        <div style={{ display: "flex" }}>
          <h3>Address: </h3>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>
            525 East 71st Street, 5th Floor New York, NY 10021
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <h3>State Licensure: </h3>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>
            New York, Massachusetts
          </p>
        </div>

        <div style={{ display: "flex" }}>
          <h3>Special Expertise: </h3>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>Myopathy,</p>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>Neuropathy,</p>
          <p style={{ fontSize: "17px", paddingLeft: "10px" }}>
            Myasthenia gravis
          </p>
        </div>
      </div>

      <div
        style={{
          width: "25%",
          maxWidth: "100%",
          textAlign: "center",
        }}
      >
        <img className="hero-image1" src={Doctor} alt="Doctor" />
      </div>
    </div>
  );
}

export default Hero;
