import React from "react";
import "./About.css";

function About() {
  return (
    <div
      id="about"
      style={{
        width: "100%",
      }}
    >
      <h1 style={{ fontWeight: "bold", marginTop: 30 }}>About Dr. Zhou</h1>

      <hr
        style={{
          border: "1px solid black",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
      />
      <p style={{ fontSize: "17px", marginTop: 30, lineHeight: "25px" }}>
        Dr. Lan Zhou is Neurologist-in-Chief and Chair of the Marcia Dunn and
        Jonathan Sobel Department of Neurology at Hospital for Special Surgery.
        She specializes in the care and treatment of patients with neuromuscular
        diseases and regularly performs and interprets nerve, muscle, and skin
        biopsies as part of her practice. She has special interests in small
        fiber neuropathy, inflammatory myopathy, myasthenia gravis, and
        neuromuscular pathology.
      </p>
      <p style={{ fontSize: "17px", marginTop: 30, lineHeight: "25px" }}>
        Dr. Lan Zhou is Neurologist-in-Chief and Chair of the Marcia Dunn and
        Jonathan Sobel Department of Neurology at Hospital for Special Surgery.
        She specializes in the care and treatment of patients with neuromuscular
        diseases and regularly performs and interprets nerve, muscle, and skin
        biopsies as part of her practice. She has special interests in small
        fiber neuropathy, inflammatory myopathy, myasthenia gravis, and
        neuromuscular pathology.Dr. Zhou is a nationally renowned neuromuscular
        specialist with a long history of clinical and academic accomplishments.
        She earned her medical degree from Shanghai Medical University School of
        Medicine in China (now Fudan University Medical Center) in 1989 before
        completing her PhD in developmental biology at the University of
        Cincinnati College of Medicine in 1995. Previously, Dr. Zhou served as a
        staff neurologist at Cleveland Clinic, an associate professor of
        neurology, neurosurgery, and pathology at Icahn School of Medicine at
        Mount Sinai, a professor of neurology and pathology at the University of
        Texas Southwestern Medical Center, and a professor of neurology and
        pathology and Vice Chair for Research in the Department of Neurology at
        Boston University School of Medicine.
      </p>
      <p style={{ fontSize: "17px", marginTop: 30, lineHeight: "25px" }}>
        Additionally, Dr. Zhou is recognized as an outstanding educator and runs
        a National Institutes of Health–funded research program to study muscle
        inflammation, fibrosis, and regeneration associated with acute and
        chronic skeletal muscle injuries and repair. She has mentored many
        fellows, residents, and medical students to conduct clinical and basic
        research. Her research team has published extensively in the biomedical
        journals. She has given many invited talks and lectures nationally and
        internationally.f
      </p>

      <h1 style={{ fontWeight: "bold", marginTop: 20 }}>Departments</h1>
      <p style={{ fontSize: "17px", marginTop: 10 }}>Neurology</p>
      <h1 style={{ fontWeight: "bold", marginTop: 20 }}>Special Expertise</h1>
      <p style={{ fontSize: "17px", marginTop: 10 }}>Myopathy</p>
      <p style={{ fontSize: "17px", marginTop: 10 }}>Neuropathy</p>
      <p style={{ fontSize: "17px", marginTop: 10 }}>Myasthenia gravis</p>
      <p style={{ fontSize: "17px", marginTop: 10 }}>Neuromuscular pathology</p>
      <h1 style={{ fontWeight: "bold", marginTop: 20 }}>Procedures</h1>
      <p style={{ fontSize: "17px", marginTop: 10 }}>NCS/EMG</p>
      <p style={{ fontSize: "17px", marginTop: 10 }}>
        Muscle, nerve, and skin biopsies
      </p>
    </div>
  );
}

export default About;
