import React from "react";
import "../Styles/Footer.css";
import logo from "../Assets/logos/SKBZ-logo.png";
import { useNavigate } from "react-router-dom";
import FooterLogo from "./Footer/FooterLogo";

function Footer() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="ft-info">
          <div className="ft-info-p1">
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                justifyItems: "center",
                color: "white",
                letterSpacing: ".6px",
                textDecoration: "none",
                marginLeft: "5px",
              }}
              onClick={handleClick}
            >
              <img
                src={logo}
                style={{ width: "80px", height: "80px", marginRight: "15px" }}
                alt="News"
              />
              SKBZ-MZD+
            </h1>
            <p className="ft-description">
              Talk to online doctors and get medical advice, online
              prescriptions, refills and medical notes within minutes. On-demand
              healthcare services at your fingertips.
            </p>
          </div>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">General</p>
          <ul className="ft-list-items">
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/departments">Departments</a>
            </li>
            <li>
              <a href="/doctors">Doctors</a>
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
          </ul>
        </div>

        <div className="ft-list">
          <p className="ft-list-title">Legal</p>
          <ul className="ft-list-items">
            <li>
              <a href="/news">News</a>
            </li>
            <li>
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
            <li>
              <a href="/terms-of-use">Terms of Services</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>

        <div className="ft-list" id="contact">
          <p className="ft-list-title">Talk To Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="https://www.google.com/maps/place/SKBZ+CMH+Muzaffarabad/@34.3723873,73.4654228,18.96z/data=!4m15!1m8!3m7!1s0x38e09e278043697b:0xf58c7ec6413bae39!2sNew+CMH+Rd,+Muzaffarabad!3b1!8m2!3d34.3724074!4d73.4649705!16s%2Fg%2F1xn_ls87!3m5!1s0x38e09e27a61054d7:0x92c0af415acf44df!8m2!3d34.3730692!4d73.4659907!16s%2Fg%2F1td79jfz?entry=ttu">
                Address: 9FF8+69H, CMH Rd, Muzaffarabad
              </a>
            </li>
            <li>
              <a href="https://www.google.com/maps/place/SKBZ+CMH+Muzaffarabad/@34.3723873,73.4654228,18.96z/data=!4m15!1m8!3m7!1s0x38e09e278043697b:0xf58c7ec6413bae39!2sNew+CMH+Rd,+Muzaffarabad!3b1!8m2!3d34.3724074!4d73.4649705!16s%2Fg%2F1xn_ls87!3m5!1s0x38e09e27a61054d7:0x92c0af415acf44df!8m2!3d34.3730692!4d73.4659907!16s%2Fg%2F1td79jfz?entry=ttu">
                Azad Jammu and Kashmir
              </a>
            </li>
            <li>
              <a href="mailto:support@skbz-mzd.com">support@skbz-mzd.com</a>
            </li>
            <li>
              <a href="tel:+00925822">Tel 00925822-920524</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="ft-copyright"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p>This site is powered by</p>
        <FooterLogo />
      </div>
    </div>
  );
}

export default Footer;
