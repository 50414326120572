import React from "react";
import InformationCard from "./InformationCard";
import { faInstitution } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

const Objectives = [
  {
    title: "Innovate Relentlessly",
    description:
      "To spearhead revolutionary medical research and integrate state-of-the-art technologies, pushing the envelope of what is medically possible and redefining standards of care.",
    icon: faInstitution,
  },
  {
    title: "Empower Patients",
    description:
      "To foster an environment where patients are partners in their own care, providing education, support, and resources that empower them to make informed decisions and actively engage in their health journeys.",
    icon: faInstitution,
  },
  {
    title: "Cultivate Compassion",
    description:
      "To ensure that every interaction, from the operating room to the reception desk, is imbued with genuine empathy, respect, and kindness, creating an atmosphere where healing is holistic and humane.",
    icon: faInstitution,
  },
  {
    title: "Champion Wellness",
    description:
      "To go beyond treating illness by promoting proactive wellness initiatives, preventive care, and lifestyle education that pave the way for long-term health and vitality.",
    icon: faInstitution,
  },
  {
    title: "Nurture Talent",
    description:
      "To attract, develop, and retain a team of extraordinary professionals who are not only skilled in their fields but are also driven by a passion for excellence and a deep commitment to our mission and vision.",
    icon: faInstitution,
  },
  {
    title: "Build Community",
    description:
      "To forge strong, meaningful connections with the communities we serve, acting as a pillar of support, education, and inspiration, and contributing to the overall well-being and resilience of society.",
    icon: faInstitution,
  },
];

function Info() {
  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Our Mission</span>
        </h3>
        <p className="info-description">
          At SKBZ, we are architects of healing and pioneers of hope. Our
          mission is to transcend the ordinary boundaries of healthcare,
          crafting an unparalleled sanctuary where every patient’s journey is a
          symphony of compassion, innovation, and excellence. We are dedicated
          to weaving the threads of cutting-edge medicine with the timeless
          fabric of human touch, transforming lives with every heartbeat, every
          breath, and every step towards recovery.
        </p>
      </div>
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Vision</span>
        </h3>
        <p className="info-description">
          We envision a world where healthcare transcends the imaginable, a
          realm where SKBZ stands as a beacon of brilliance and a paragon of
          patient-centric care. Our vision is to sculpt an avant-garde
          healthcare paradigm, where technology harmonizes with empathy, and
          where every patient is not merely treated but profoundly understood
          and valued. We aspire to be the cradle of groundbreaking medical
          advancements and the heart of holistic well-being, illuminating the
          path to a healthier, more radiant future for all.
        </p>
      </div>

      <h3 className="info-title">
        <span>Objectives</span>
      </h3>
      <div className="info-cards-content">
        {Objectives?.map((item, index) => {
          return (
            <InformationCard
              key={index}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Info;
