import React, { useEffect, useState } from "react";
import {
  faMedkit,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import InformationCard from "../Components/InformationCard";


function Departments() {
  const [depData, setDepData] = useState();

  useEffect(() => {
    getDepartmentList();
  }, []);

  const getDepartmentList = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    // myHeaders.append("Authorization", "••••••");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://skbz-mzd.com/admin/api/v1/all-departments",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDepData(result?.data?.departmentData);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="info-section" id="services">
      {depData?.map((item, index) => {
        return (
          <div className="info-title-content" key={index}>
            <h3 className="info-title">
              <span>{item?.title}</span>
            </h3>
            <p className="info-description">{item?.description}</p>
            <h2 className="info-subheading">{item?.cat_dep_name_1}</h2>
            <div className="info-cards-content">
              {item?.sub_department?.map((item) => {
                return (
                  <InformationCard
                    key={item?.id}
                    title={item?.name}
                    description={item?.description}
                    icon={faMedkit}
                  />
                );
              })}
            </div>
            <h2 className="info-subheading">{item?.cat_dep_name_2}</h2>
            <div className="info-cards-content">
              {item?.sub_department_2?.map((item) => {
                return (
                  <InformationCard
                    key={item?.id}
                    title={item?.name}
                    description={item?.description}
                    icon={faMedkit}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Departments;
