import React from "react";
import "../Styles/LegalDocs.css";

const data1 = [
  "Personal Information: This includes information that can be used to identify you, such as your name, address, email address, phone number, date of birth, and health-related information.",
  "Non-Personal Information: This includes information that cannot be used to identify you, such as anonymous usage data, general demographic information, and aggregated information.",
  "Usage Data: We collect information about how you use our website, including your IP address, browser type, pages visited, time and date of visits, and other diagnostic data.",
  "Cookies and Tracking Technologies: We use cookies, web beacons, and similar tracking technologies to track activity on our website and store certain information. Cookies are small data files stored on your device that help us improve our website and your user experience.",
];

const data2 = [
  "Providing and Managing Services: To provide, manage, and improve our healthcare services, including scheduling appointments, processing payments, and managing patient records.",
  "Communication: To communicate with you regarding your appointments, medical treatment, test results, and other relevant information. We may also send you updates, newsletters, and promotional materials.",
  "Personalization: To personalize your experience on our website and tailor our services to your needs and preferences.",
  "Analytics and Improvements: To analyze usage patterns, monitor and analyze trends, and improve our website's functionality, security, and user experience.",
  "Legal Compliance: To comply with legal obligations, protect your vital interests, and enforce our terms and policies.",
];

const data3 = [
  "Healthcare Providers: We may share your information with healthcare providers involved in your care, including doctors, nurses, and other medical staff, to ensure you receive the best possible treatment.",
  "Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processors, IT service providers, and marketing firms. These service providers are required to protect your information and use it only for the purposes specified by us.",
  "Legal Requirements: We may disclose your information if required by law or in response to valid requests by public authorities, such as court orders, subpoenas, or government regulations.",
  "Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you before your information is transferred and becomes subject to a different privacy policy.",
  "Protection of Rights: We may disclose your information to protect and defend our rights and property, prevent fraud, and ensure the safety and security of our users and services.",
];
const data4 = [
  "Encryption: We use encryption to protect sensitive information transmitted online.",
  "Access Controls: We restrict access to your information to authorized personnel who need it to perform their job functions.",
  "Regular Security Audits: We conduct regular security audits and assessments to identify and address potential vulnerabilities.",
  "Data Minimization: We limit the collection and retention of your information to what is necessary for the purposes outlined in this Privacy Policy.",
];

function PrivacyPolicy() {
  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">Privacy Policy</h1>

      <div className="legal-text-content">
        {/* <p className="legal-title">General Info</p> */}
        <p className="legal-description">
          At Sheikh Khalifa Bin Zayed Al Nahyan Hospital, Muzaffarabad
          ("SKBZ-MZD Hospital," "we," "our," or "us"), we are committed to
          protecting the privacy and security of our patients, visitors, and
          website users ("you" or "your"). This Privacy Policy outlines how we
          collect, use, disclose, and safeguard your information when you visit
          our website [insert website URL] and use our services. By accessing or
          using our website, you agree to the terms of this Privacy Policy.
        </p>

        <p className="legal-subtitle">Information We Collect</p>
        <p className="legal-description">
          We collect various types of information from our website visitors and
          service users, including:
        </p>
        {data1?.map((item, index) => {
          return (
            <li className="legal-description" key={index}>
              {item}
            </li>
          );
        })}

        <p className="legal-subtitle">How We Use Your Information</p>
        <p className="legal-description">
          We use the information we collect for various purposes, including:
        </p>
        {data2?.map((item, index) => {
          return (
            <li className="legal-description" key={index}>
              {item}
            </li>
          );
        })}
        <p className="legal-subtitle">Disclosure of Your Information</p>
        <p className="legal-description">
          We may disclose your information in the following circumstances:
        </p>
        {data3?.map((item, index) => {
          return (
            <li className="legal-description" key={index}>
              {item}
            </li>
          );
        })}
        <p className="legal-subtitle">Security of Your Information</p>
        <p className="legal-description">
          We implement a variety of security measures to protect your
          information from unauthorized access, use, or disclosure. These
          measures include:
        </p>
        {data4?.map((item, index) => {
          return (
            <li className="legal-description" key={index}>
              {item}
            </li>
          );
        })}
      </div>
    </div>
  );
}

export default PrivacyPolicy;
