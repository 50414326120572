import React from "react";

const IndustryRelationshipsArray = [
  "Dr. Zhou's clinical research is to utilize muscle, nerve, and skin biopsy databases to study etiologies, clinical features, and treatment responses of neuromuscular diseases, especially small fiber neuropathy. Her basic research has been focused on the roles of macrophages and fibro/adipogenic progenitors in skeletal muscle injury and repair. She has published extensively in biomedical journals. Her clinical research goal is to improve the diagnosis and management of neuromuscular diseases. Her basic research goal is to develop novel pharmacotherapies to modify inflammation, reduce fibrosis, promote regeneration, and improve muscle function and muscular dystrophy phenotype.",
];
function IndustryRelationships() {
  return (
    <div
      id="industry"
      style={{
        width: "100%",
      }}
    >
      <h1 style={{ fontWeight: "bold", marginTop: 30 }}>Industry Relationships</h1>
      <hr
        style={{
          border: "1px solid black",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
      />
      {IndustryRelationshipsArray.map((item, index) => {
        return (
          <p
            key={index}
            style={{
              fontSize: "17px",
              marginTop: 5,
              lineHeight: "25px",
              textAlign: "justify",
            }}
          >
            {item}
          </p>
        );
      })}
    </div>
  );
}

export default IndustryRelationships;
