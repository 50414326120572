import React from "react";
import "../Styles/LegalDocs.css";

const data1 = [
  {
    title: "Introduction",
    content:
      "Welcome to Sheikh Khalifa Bin Zayed Al Nahyan Hospital, Muzaffarabad (“SKBZ-MZD Hospital,” “we,” “our,” or “us”). By accessing and using our website [insert website URL] and services, you agree to comply with and be bound by the following Terms of Service. These terms govern your use of our website, services, and any content or materials provided therein. Please read these terms carefully before using our services. If you do not agree with any part of these terms, please refrain from using our website and services.",
  },
  {
    title: "Use of Services",
    content:
      "You agree to use our website and services only for lawful purposes and in accordance with these Terms of Service. You are responsible for ensuring that your use of our website does not violate any applicable laws or regulations. You may not use our website or services to: \n- Engage in any illegal or unauthorized activities. \n- Post or transmit any content that is defamatory, obscene, harmful, or otherwise objectionable. \n- Infringe upon the intellectual property rights of others. \n- Interfere with the operation of our website or services, including through the use of viruses, bots, or other harmful code.",
  },
  {
    title: "User Accounts",
    content:
      "To access certain features of our website or services, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep this information up-to-date. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you suspect any unauthorized use of your account, you must notify us immediately.",
  },
  {
    title: "Intellectual Property",
    content:
      "All content, materials, and features available on our website, including text, graphics, logos, images, and software, are the property of SKBZ-MZD Hospital or our licensors and are protected by intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content from our website without our prior written consent.",
  },
  {
    title: "Limitation of Liability",
    content:
      "To the fullest extent permitted by law, SKBZ-MZD Hospital shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of our website or services. This includes, but is not limited to, damages for loss of profits, data, or other intangibles, even if we have been advised of the possibility of such damages.",
  },
  {
    title: "Changes to Terms",
    content:
      "We reserve the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our website and services following the posting of revised terms constitutes your acceptance of the changes. We encourage you to review these terms periodically to stay informed of any updates.",
  },
  {
    title: "Governing Law",
    content:
      "These Terms of Service shall be governed by and construed in accordance with the laws of Pakistan, without regard to its conflict of law principles. Any disputes arising under or in connection with these terms shall be subject to the exclusive jurisdiction of the courts located in Muzaffarabad, Azad Jammu and Kashmir.",
  },
  {
    title: "Contact Us",
    content:
      "If you have any questions or concerns about these Terms of Service, please contact us at: \nSheikh Khalifa Bin Zayed Al Nahyan Hospital, Muzaffarabad \n[Insert Hospital Address] \nPhone: [Insert Phone Number] \nEmail: [Insert Email Address] \nBy using our website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. Thank you for choosing Sheikh Khalifa Bin Zayed Al Nahyan Hospital, Muzaffarabad.",
  },
];

function Termsofuse() {
  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">Terms of Service</h1>
      <div className="legal-text-content">
        {data1?.map((item, index) => {
          return (
            <div key={index}>
              <p className="legal-subtitle">{item.title}</p>
              <p className="legal-description" key={index}>
                {item.content}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Termsofuse;
