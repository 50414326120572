import React, { useEffect, useState } from "react";
import "../../Styles/NewsList.css";
import logo from "../../Assets/News-Banner.png";
import { useParams } from "react-router-dom";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const News = () => {
  const [newsItem, setNewsItem] = useState(null); // Initialize with null to indicate data is not yet fetched
  const { slug } = useParams();

  useEffect(() => {
    getNewsItem();
  }, [slug]);

  const getNewsItem = () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://dev.skbz-mzd.com/admin/api/v1/all-news`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const foundNews = result?.data?.NewsData.find(
          (news) => news.id == slug
        );
        setNewsItem(foundNews);
      })
      .catch((error) => console.error(error));
  };


  return (
    <div className="news-page">
      <div style={{ width: "100%", height: "40vh" }}>
        <img style={{ width: "100%", height: "100%" }} src={logo} alt="News" />
      </div>
      <div className="news-page-section">
        <h2
          style={{
            marginBottom: "30px",
            fontWeight: "normal",
            paddingTop: "20px",
          }}
        >
          NEWS & ANNOUNCEMENT
        </h2>
        <div className="news-section-list">
          <div className="news-section-list-item" key={newsItem?.id}>
            <h4 style={{ marginBottom: "10px" }}>{newsItem?.name}</h4>
            <p>
              <FontAwesomeIcon className="info-fa-icon" icon={faCalendarDays} />{" "}
              {newsItem?.created_at}
            </p>
            <div className="image-side-list-item">
              <img src={newsItem?.img} alt="News" />
            </div>
          </div>
          <div
            className="details-news"
            dangerouslySetInnerHTML={{ __html: newsItem?.description }}
          />
        </div>
      </div>
    </div>
  );
};

export default News;

