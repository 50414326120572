import React from "react";
import "../Styles/Doctors.css";

function DoctorCard(props) {
  // const departmentNames = props?.dep?.map((department) => department.name);
  // const depNames = departmentNames?.join(", ");
  return (
    <div className="dt-card">
      <img src={props.img} alt={props.name} className="dt-card-img" />
      <p className="dt-card-name">{props.name}</p>
      <p className="dt-card-title">{props.title}</p>
      <p className="dt-card-title">{props.degree}</p>
    </div>
  );
}

export default DoctorCard;
