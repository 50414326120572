import React from "react";

const insuranceArray = [
  " BCBS_PPO_POS",
  "BCBS_HMO",
  "BCBS Anthem of NY (formerly Empire)",
  "BCBS Horizon",
  "Cigna",
  "EmblemHealth GHI",
  "EmblemHealth HIP",
  "EmblemHealth Medicare",
  "EmblemHealth Select Care",
  "EmblemHealth Vytra",
  "The Empire Plan / NYSHIP",
  "Medicare Part B",
  "Oxford",
  "UnitedHealthcare",
  "UnitedHealthcare Compass",
  "UnitedHealthcare Medicare HMO",
  "UnitedHealthcare Medicare PPO",
];

function Insurance() {
  return (
    <div
      id="insurance"
      style={{
        width: "100%",
      }}
    >
      <h1 style={{ fontWeight: "bold", marginTop: 30 }}>Insurances</h1>
      <hr
        style={{
          border: "1px solid black",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
      />
      {insuranceArray.map((item, index) => {
        return (
          <p key={index} style={{ fontSize: "17px", marginTop: 10 }}>
            {item}
          </p>
        );
      })}
      <p
        style={{
          fontSize: "17px",
          marginTop: 20,
          lineHeight: "25px",
          textAlign: "justify",
        }}
      >
        This provider is currently in the process of joining the networks of
        many commercial insurance plans and should show as a participating
        provider soon.
      </p>
      <p
        style={{
          fontSize: "17px",
          marginTop: 20,
          lineHeight: "25px",
          textAlign: "justify",
        }}
      >
        If your insurance is not listed, please call our office if you have
        questions regarding your insurance coverage. If you have out-of-network
        benefits, then your insurance may reimburse you for a portion of your
        office visit. We will work with you and your insurance to minimize your
        out-of-pocket costs. Financial assistance may be available for patients
        in need.
      </p>
    </div>
  );
}

export default Insurance;
