import React from "react";

const docHeadings = [
  {
    id: "#about",
    name: "About",
  },
  {
    id: "#insurance",
    name: "Insurance",
  },
  {
    id: "#credentials",
    name: "Credentials",
  },
  {
    id: "#publications",
    name: "Publications",
  },
  {
    id: "#research",
    name: "Research",
  },
  {
    id: "#industry",
    name: "Industry",
  },
];

function Sidebar() {
  return (
    <div
      style={{
        width: "100%",
        position: "sticky",
        top: "10px", // Adjust this value as needed
        padding: "10px", // Add some padding for better appearance
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#ECF1F4",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "10%",
            margin: "10%",
          }}
        >
          <h2>Lan Zhou, MD</h2>
          <h3>Neurology</h3>
          <button
            style={{
              padding: "14px 20px",
              margin: "14px 20px",
              color: "white",
              border: "1px solid transparent",
              borderRadius: "5px",
              outline: "transparent",
              backgroundColor: "#1A8EFD",
              fontSize: "18px",
              letterSpacing: ".8px",
              cursor: "pointer",
              transition: "all .4s ease",
              width: "100%",
            }}
            type="button"
          >
            Call +92-325-5225474
          </button>
          <hr
            style={{
              border: "1px solid black",
              marginTop: "20px",
              marginBottom: "20px",
              width: "90%",
            }}
          />
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {docHeadings?.map((item, index) => {
              return (
                <a
                  href={item.id}
                  className=".underline-on-hover:hover"
                  style={{
                    color: "#0074BC",
                    fontSize: "22px",
                    marginBottom: 10,
                    textDecoration: "none",
                  }}
                >
                  {item.name}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
