import React from "react";

import About from "../Components/DoctorsComp/About";
import Hero from "../Components/DoctorsComp/Hero";
import Insurance from "../Components/DoctorsComp/Insurance";
import Credentials from "../Components/DoctorsComp/Credentials";
import Publications from "../Components/DoctorsComp/Publications";
import Research from "../Components/DoctorsComp/Research";
import IndustryRelationships from "../Components/DoctorsComp/IndustryRelationships";
import Sidebar from "../Components/DoctorsComp/Sidebar";

function DoctorsInfo() {
  return (
    <>
      <Hero />
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "70%", padding: "1% 5%" }}>
          <About />
          <Insurance />
          <Credentials />
          <Publications />
          <Research />
          <IndustryRelationships />
        </div>
        <div
          style={{
            display: "flex",
            width: "30%",
            position: "sticky",
            top: "10px", // Adjust this value as needed
          }}
        >
          <Sidebar />
        </div>
      </div>
    </>
  );
}

export default DoctorsInfo;
