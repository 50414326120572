import React from "react";
import "../Styles/AboutImageCard.css";

function AboutImageCard(props) {
  return (
    <div className="dt-about-card">
      <img src={props.img} alt={props.name} className="dt-about-card-img" />
    </div>
  );
}

export default AboutImageCard;
