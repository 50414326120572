import React from "react";

const PublicationsArray = [
  {
    heading: "Selected Journal Articles",
    appointmentArr: [
      "Wang X, Zhou L. (2023) The Multifaceted Role of Macrophages in Homeostatic and Injured Skeletal Muscle. Front Immunol. 14:1274816. doi: 10.3389/fimmu.2023.1274816.",
      "Abrams RMC, Zhou L, Shin SC. (2023) Persistent post-COVID-19 neuromuscular symptoms. Muscle Nerve. 68(4):350-355.",
      "Wang X, Chen J, Homma S, Wang Y, Smith GR, Ruf-Zamojski F, Sealfon SC, Zhou L. (2023) Diverse Effector and Regulatory Functions of Fibro/Adipogenic Progenitors during Skeletal Muscle Fibrosis in Muscular Dystrophy. iScience. PMID: 36594034 DOI: 10.1016/j.isci.2022.105775.",
      "Wang X, Zhou L. (2022) The Many Roles of Macrophages in Skeletal Muscle Injury and Repair. Front Cell Dev Biol. 10:952249.",
      "Abrams RMC, Simpson DM, Navis A, Jette N, Zhou L, Shin SC. (2022) Comment on small fiber neuropathy associated with SARS-CoV-2 infection: Author response. Muscle Nerve. 65(6):E32-E33.",
      "Bhadola S, Tang C, Marks A, Kaku MC, Zhou L, Siao P, Lau KHV. (2022) Disparate healthcare access and telehealth-based hybrid consultations during the COVID-19 pandemic. Work. 73(2):377-382.",
      "Abrams RMC, Simpson DM, Navis A, Jette N, Zhou L, Shin SC. (2022) Small fiber neuropathy associated with SARS-CoV-2 infection. Muscle Nerve. 65(4):440-443",
      "Rodríguez-Moreno J, Zhou L, Arkun K, Bali D, Reed D, Kalish R, Vullaganti M, Bali T, Soto O. (2022) Tarui Disease Caused by a Novel PFKM Genetic Variant in a Sub-Saharan African Patient. J Clin Neuromuscul Dis. 23(3):162-164.",
      "Zhou L. (2021) Small fiber neuropathy in the elderly. Clin Geriatr Med. 37(2):279-288.",
      "Zhou L, Siao P. (2021) Lateral femoral cutaneous neuropathy caused by prone positioning to treat COVID-19-associated acute respiratory distress syndrome. Muscle Nerve. 63:E50-E52.",
    ],
  },
  {
    heading: "Selected Journal Articles",
    appointmentArr: [
      "Zhou L, Burns DK, Cai C. (2020) A Case-Based Guide to Neuromuscular Pathology. Springer",
    ],
  },
];

function Publications() {
  return (
    <div
      id="publications"
      style={{
        width: "100%",
      }}
    >
      <h1 style={{ fontWeight: "bold", marginTop: 30 }}>
        Publications by Dr. Zhou
      </h1>
      <hr
        style={{
          border: "1px solid black",
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
        }}
      />
      {PublicationsArray.map((item, index) => {
        return (
          <div>
            <h2
              key={index}
              style={{ fontWeight: "bold", marginTop: 30, marginBottom: 30 }}
            >
              {item.heading}
            </h2>
            {item.appointmentArr.map((innerItem, innerIndex) => {
              return (
                <p
                  key={innerIndex}
                  style={{
                    fontSize: "17px",
                    marginTop: 5,
                    lineHeight: "25px",
                    textAlign: "justify",
                  }}
                >
                  {innerItem}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Publications;
