// src/Pages/Contact.js
import React from "react";
import AboutImageCard from "../Components/AboutImageCard";
import "../Styles/Gallery.css";

const galleryImages = [
  {
    id: 1,
    depName: "Paeds Department",
    depImages: [
      {
        depImage: require("../Assets/Gallery/6.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/7.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/8.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 2,
    depName: "Emergency",
    depImages: [
      {
        depImage: require("../Assets/Gallery/9.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 3,
    depName: "Gynae OPD",
    depImages: [
      {
        depImage: require("../Assets/Gallery/10.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/11.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 4,
    depName: "NICU",
    depImages: [
      {
        depImage: require("../Assets/Gallery/12.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/20.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 5,
    depName: "Officer Ward / Private rooms",
    depImages: [
      {
        depImage: require("../Assets/Gallery/13.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 6,
    depName: "Rehabilitation Department",
    depImages: [
      {
        depImage: require("../Assets/Gallery/14.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/15.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/16.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 7,
    depName: "Doctor's Hostel",
    depImages: [
      {
        depImage: require("../Assets/Gallery/17.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 8,
    depName: "Library",
    depImages: [
      {
        depImage: require("../Assets/Gallery/18.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 9,
    depName: "CCU",
    depImages: [
      {
        depImage: require("../Assets/Gallery/19.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/21.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
  {
    id: 10,
    depName: "Speech Therapy Department",
    depImages: [
      {
        depImage: require("../Assets/Gallery/1.jpeg"),
        depImageName: "Speech Therapy Department",
      },
      {
        depImage: require("../Assets/Gallery/22.jpeg"),
        depImageName: "Speech Therapy Department",
      },
    ],
  },
];

const Gallery = () => {
  return (
    <div className="gallery-section">
      <div className="gallery-title-content">
        <h3 className="gallery-title">
          <span>Gallery</span>
        </h3>
      </div>

      {galleryImages?.map((item, index) => {
        return (
          <div className="gallery-cards" key={index}>
            <h2 className="gallery-subheading">{item?.depName}</h2>
            <div className="gallery-cards-item">
              {item?.depImages?.map((innerItem, innerIndex) => {
                return (
                  <AboutImageCard key={innerIndex} img={innerItem?.depImage} />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Gallery;
